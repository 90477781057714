h1, .h1-title {
    @include font-style($Open-sans,22px,32px,$bold);
    color: $white;
    margin: 0;
}

h2, .h2-title {
    @include font-style($Open-sans,22px,32px,$bold);
    color: $white;
    margin: 0;
}

h3, .h3-title {
    @include font-style($Open-sans,22px,32px,$bold);
    color: $white;
    margin: 0;
}