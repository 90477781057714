@import '../../styles/scss/custom-bootstrap/functions';
@import '../../styles/scss/custom-bootstrap/variables';
@import '../../styles/scss/custom-bootstrap/mixins';
@import '../../styles/scss/variables';
@import '../../styles/scss/mixins';

.impression {
  width: 100%;
  //height: 100vh;
  background: url('../../../public/assets/images/bg-home.png') no-repeat 0 0;
  background-size: 100%;
  position: relative;
  overflow-y: hidden;
  padding-top: 70px;
  height: 100vh;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      180.03deg,
      #4aa3a2 -1.76%,
      rgba(74, 163, 162, 0.53) 87.95%
    );
    z-index: 1;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 70px);
    overflow-y: auto;
    z-index: 2;

    .sub-title {
      @include font-style($Open-sans, 14px, 20px, $medium);
      color: $white;
      font-style: italic;
      margin-bottom: 15px;
    }

    .tirage {
      @include font-style($Open-sans, 16px, 24px, $medium);
      color: $white;
      margin-bottom: 15px;
    }
  }
}

.photo {
  &__receive {
    /*position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $white;
        border-radius: 32px 32px 0px 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        */
    padding: 15px;

    .logo {
      margin: 35px 0 14px;
    }

    .btn-pink {
      &:first-child {
        &:hover {
          background: $brand-primary;
          color: $white;
          border-color: $brand-primary;
        }
      }
    }
  }

  &__bottom {
    margin-top: auto;
    padding-bottom: 120px;
  }

  &__mail {
    display: inline-flex;
    margin-bottom: 38px;
    width: 100%;
  }

  &__print {
    display: inline-flex;
  }

  &__text {
    @include font-style($Open-sans, 16px, 24px, $bold);
    color: $grey1;
    text-align: center;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: $white1;

  &__heading {
    font-weight: $semi-bold;
    color: $white1;
  }

  &__text {
    @include font-style($vintage, 30px, 30px, $bold);
    color: $white1;
    margin: 10px 0 30px;
  }
}

.rocket {
  display: inline-block;
  width: 81px;
  height: 113px;
  background: url('../../../public/assets/images/rocket.svg') no-repeat 0 0;
}
