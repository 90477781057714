// GLOBAL STYLES ONLY

// SETTINGS - contains fonts, colors definitions, mixins, etc
@import '~react-svg-donuts/src/style.css';
@import '~bootstrap/scss/bootstrap';
@import 'scss/settings';

.offcanvas.offcanvas-bottom{
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: auto;
}
.donut {
  width: 105px;
  height: 120px;
  .donut__text {
    font-family: 'Open Sans';
    color: #CA3C66;
    font-size: 40px;
    display: flex;
    justify-content: center;
    strong {
      display: flex;
      background: white;
      border-radius: 50%;
      width: 95px;
      z-index: 1;
      height: 95px;
      justify-content: center;
      align-items: center;
    }
  }
  .donut__circle {
    stroke: #CA3C66;
  }
}

// ELEMENTS - styling for bare HTML elements (like H1, A, etc.)
//@import 'elements/elements';
