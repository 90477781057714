.btn {
    @include font-style($Open-sans, 18px, 18px, $semi-bold);
    color: $white;
    padding: 19px 20px;
    border-radius: 28px;
    width: 100%;
    transition: none;

    &-pink {
        background: $brand-primary;

        &:hover {
            background: $brand-primary;
            color: $white;
        }
        &:disabled {
            background-color: #ca3c6680 !important;
            border-color: #ca3c6680 !important;
            color: white !important;
        }
    }

    &-white {
        background: $white !important;
        color: $brand-primary !important;

        &:hover {
            background: $white;
            color: $brand-primary;
        }
        &:disabled {
            background-color: #d6d3d3 !important;
            border-color: #d6d3d3 !important;
            color: #ca3c6680 !important;
        }
    }

    &-retake {
        color: $brand-primary;

        &:hover {
            color: $white;
            background: none;
            border: none;
        }
        &:disabled {
            border: none !important;
            color: #ca3c6680 !important;
        }
    }

    &-loue {
        //color: $brand-primary !important;
        border: none;
        background: none;
        padding: 0;
        text-decoration-line: underline;
    }

    &:first-child {
        &:hover,
        &:focus,
        &:active {
            .photo__receive & {
                color: $brand-primary;
                background: none;
            }
        }
    }

    &-photo {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &-print {
        padding: 0;
        border: none;
        background: none;

        &__label {
            fill: $white;
        }

        &__bg {
            fill: $brand-primary;
        }

        &:disabled {
            .btn-print__label {
                fill: $grey2;
            }

            .btn-print__bg {
                fill: $white2;
            }
        }
    }
}

.green {
    color: $green;
}
