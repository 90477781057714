.cmd {
  background-color: #CA3C66;
  color: white;
  border-color: #CA3C66;
  border-radius: 64px !important;
  z-index: 888;
  &.disabled {
    background: #F2F4F5;
    color: #A3A5A6;
    border-color: #F2F4F5;
  }
}
.input_group_number {
  border:1px solid #CA3C66;
  border-radius: 64px !important;
  margin-bottom: 24px;
}
.input__number {
  border-width: 0;
}
.form_input_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.impr__confirmation {
  width: 100%;
}