@font-face {
  font-family: 'PT Sans';
  src: local('PT Sans Bold'), local('PTSans-Bold'),
    url('../fonts/PTSans-Bold.woff2') format('woff2'),
    url('../fonts/PTSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family:'Open Sans';
  src: url('../fonts/Open Sans Regular.eot');
  src: url('../fonts/Open Sans Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Open Sans Regular.woff2') format('woff2'),
  url('../fonts/Open Sans Regular.woff') format('woff'),
  url('../fonts/Open Sans Regular.svg#Open Sans Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DC;
}

@font-face {
  font-family:'Fenway Vintage';
  src: url('../fonts/Fenway Vintage.eot');
  src: url('../fonts/Fenway Vintage.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Fenway Vintage.woff2') format('woff2'),
  url('../fonts/Fenway Vintage.woff') format('woff'),
  url('../fonts/Fenway Vintage.svg#Fenway Vintage') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-007C;
}


