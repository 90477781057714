@import '../../styles/scss/custom-bootstrap/functions';
@import '../../styles/scss/custom-bootstrap/variables';
@import '../../styles/scss/custom-bootstrap/mixins';
@import '../../styles/scss/variables';
@import '../../styles/scss/mixins';

.home {
  width: 100%;
  height: 100vh;
  background: url('../../../public/assets/images/bg-home.png') no-repeat 0 0;
  background-size: 100%;
  position: relative;

  > div {
    height: 100%;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      180.03deg,
      #4aa3a2 -1.76%,
      rgba(74, 163, 162, 0.53) 87.95%
    );
    z-index: 1;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;
    padding-bottom: 120px;
    z-index: 2;
  }

  &__content {
    text-align: left;
    width: 100%;
    flex-grow: 1;
    @include media-breakpoint-up(sm) {
      text-align: center;
    }
  }

  &__title {
    color: $white;
    margin-bottom: 16px;
  }

  &__desc {
    @include font-style($Open-sans, 16px, 24px, $semi-bold);
    color: $white;
  }
}

.logo {
  margin: 51px 0 90px;

  &__free {
    flex-grow: 1;
    img {
      max-width: 304px;
      max-height: 100px;
    }
  }
}

.home-camera {
  width: 100%;
  height: 100vh;
  background: url('../../../public/assets/images/bg-home.png') no-repeat 0 0;
  background-size: 100%;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(
      180.03deg,
      #4aa3a2 -1.76%,
      rgba(74, 163, 162, 0.53) 87.95%
    );
  }
}
