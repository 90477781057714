// Add all element states
@mixin hover {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

// Simple Transition
@mixin transition($time, $type: ease-in-out) {
  transition: all $time $type;
}

// font
@mixin font-style($font-family, $font-size, $line-height, $font-weight) {
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
}

// Media queries using defined variables
@mixin sm {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$widescreen}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$fullhd}) {
    @content;
  }
}

/*Mobile*/
$mobile-normal-range-landscape: "(min-device-width: 576px) and (max-device-width:992px) and (orientation: landscape)";

/* Responsive Mixins */
@mixin respond($media) {
  @if $media == "mobile-normal-range-landscape" {
      @media only screen and #{$mobile-normal-range-landscape} { @content; }
  }
}

@mixin responsive-up($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
      @content;
  }
}
