$brand-primary: #ca3c66;
$brand-secondary: #e5b1d7;
$white: #ffffff;
$white1: #f9fbfc;
$white2: #f2f4f5;
$black: #000000;
$dark-gray: #2f2626;
$green: #4aa3a2;
$grey1: #464646;
$grey2: #a3a5a6;

$brand-font: "PT Sans";
$Open-sans: "Open Sans";
$vintage: "Fenway Vintage";

$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

// $tablet: 769px;
// $desktop: 960px;
// $widescreen: 1200px;
// $fullhd: 1430px;
