@import "../../styles/scss/custom-bootstrap/functions";
@import "../../styles/scss/custom-bootstrap/variables";
@import "../../styles/scss/custom-bootstrap/mixins";
@import "../../styles/scss/variables";
@import "../../styles/scss/mixins";

.home-camera {
  width: 100%;
  // height: 100vh;
  // height: 100%;
  padding: 30px 0 0;
  background: $green;
  overflow-y: auto;
  height: 100vh;

  // @include media-breakpoint-between(sm,md) {
  //     height: 100%;
  // }

  // @include respond(mobile-normal-range-landscape) {
  //     height: auto;
  // }

  // @include media-breakpoint-up(lg) {
  //     height: 100vh;
  // }

  > div {
    height: 100%;
  }

  &__wrap {
    width: 100%;
    /*height: 100vh;*/
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .export {
      position: relative;
    }
  }

  &__frame {
    /*margin: 0 auto 50px;
        width: calc(100% + 1px);
        height: 527px;*/
    //background: url('../../../public/assets/images/frame.png') no-repeat 0 0;
    // background-size: 100%;
    position: relative;
    z-index: 2;

    @include responsive-up(400px) {
      width: 352px;
    }

    img {
      width: 100%;
      height: 527px;

      @include responsive-up(400px) {
        width: auto;
      }
    }
  }
  .capture-control {
    width: 400px;
  }
}

.wrap-photo {
  position: absolute;
  top: 3.5%;
  //max-height: 62%;
  left: 0;
  background-color: white;
  right: 0;
  margin: 0 auto;
  z-index: 3;
  aspect-ratio: 243/253;
  //aspect-ratio: 86 / 89;
  //max-width: 84%;
  min-width: 90%;
  min-height: 62%;
  max-width: 90%;
  max-height: 62.5%;
  overflow: hidden;
  > div {
    position: static;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
      /* width: 350px; */
    }

    > div {
      position: static;
    }

    &.animation {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: rgba($white, 0.75);
    }
  }
}

.picture-taken {
  width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
}

.picture-upload {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.print {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__top {
    width: 100%;
  }

  &-input {
    width: 40px;
    line-height: 40px;
    text-align: center;
  }
}

.print-quantity {
  padding: 14px 14px 26px;

  p {
    @include font-style($Open-sans, 20px, 29px, $semi-bold);
  }

  &__input {
    margin: 20px 0;
    display: inline-flex;
    justify-content: center;
    border: 2px solid $brand-primary;
    border-radius: 48px;

    > * {
      &:first-child {
        margin-left: -1px;
      }
      &:last-child {
        margin-right: -1px;
      }
    }

    &-parent {
      display: flex;
      justify-content: center;
    }
  }

  &__desc {
    margin: 0 0 20px;
    @include font-style($Open-sans, 14px, 21px, $semi-bold);
    font-style: italic;
  }
}

div.upload-img {
  border: none;
  width: 40px;
  height: 40px;

  img {
    width: 40px;
    height: 40px;
  }
}

.retake {
  @include font-style($Open-sans, 18px, 18px, $bold);
  color: $brand-primary;
}

canvas {
  background: transparent;
}
.loading__container {
  background-color: black;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-pink {
  display: block;
  width: 100%;
  height: 200px;
  background: #e5b1d7;
  z-index: 8888;
}

.offcanvas-bottom.offcanvas-bottom {
  height: auto;
}
